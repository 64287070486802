:root {
  --Gray1: rgb(20, 20, 25);
  --Gray2: rgb(30, 30, 40);
  --Gray3: rgb(40, 40, 48);
  --Gray4: rgb(50, 50, 60);
  --Gray5: rgb(58, 58, 65);
  --Gray6: rgb(70, 70, 80);
  --Blue1: rgb(35, 62, 216);
  --Blue2: rgb(70, 93, 225);
  --Purple1: rgb(128, 0, 128);
  --Purple2: rgb(160, 0, 160);
  --Red1: rgb(128, 0, 0);
  --Red2: rgb(160, 0, 0);
  --Green1: rgb(0, 128, 0);
  --Green2: rgb(0, 160, 0);
  --Transparent25p: rgba(0, 0, 0, 0.25);
  --Transparent50p: rgba(0, 0, 0, 0.5);
  --Transparent75p: rgba(0, 0, 0, 0.75);
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
::-webkit-scrollbar-track {
  background-color: var(--Transparent25p);
  border-radius: 5px;
}
::-webkit-scrollbar-track:hover {
  background-color: var(--Transparent50p);
}
::-webkit-scrollbar-thumb {
  background-color: var(--Blue1);
  border-radius: 5px;
}
::-webkit-scrollbar-thumb:hover {
  background-color: var(--Blue2);
}

@keyframes fadeIn {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

.hidden {
  display: none !important;
}

input[type=text], input[type=password] {
  background-color: var(--Gray2);
  border-color: var(--Blue1);
  border-radius: 5px;
  color: #fff;
  padding: 5px;
}
input[type=file] {
  background-color: transparent;
  font-size: 1em;
  max-width: 210px;
  }
input[type=file]::file-selector-button {
  background-color: var(--Gray2);
  border-color: var(--Blue1);
  border-radius: 5px;
  color: #fff;
  padding: 5px;
  transition: ease 0.3s all;
}
input[type=file]::file-selector-button:hover {
  background-color: var(--Gray3);
  cursor: pointer;
}

.App {
  text-align: center;
  display: flex;
  width: 100vw;
  height: 100vh;
  background-color: var(--Gray3);
  flex-flow: column;
  align-items: center;
  position:fixed;
}

.App-header {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 30px;
  width: 100vw;
}

.App-link {
  color: #61dafb;
}

.titleBar {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row-reverse;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  font-size: 1.5em;
  color: white;
  background-color: var(--Gray1);
}

.hamburgerContainer {
  position: fixed;
  left: 0px;
  top: 0px;
  max-width: 150px;
}

.hamburgerBtn {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 30px;
  height: 30px;
  color: white;
  background-color: var(--Blue1);
  border: none;
  transition: ease 0.3s all;
  cursor: pointer;
  border-radius: 0 0 5px 0;
}

.hamburgerBtn:hover {
  background-color: var(--Blue2);
}

.hamburgerContents {
  position: relative;
  display: flex;
  flex-flow: column;
  left: -150px;
  top: 30px;
  background-color: var(--Transparent50p);
  transition: ease 0.3s all;
  box-shadow: 0 4px 8px 0 var(--Transparent25p), 0 6px 20px 0 var(--Transparent50p);
  padding: 2px;
  border-radius: 0 0 10px 0;
}

.hamburgerContents.active {
  left: 0px;
}

a {
  margin: 2px;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  color: white;
  font-size: .8em;
  cursor: pointer;
  background-color: var(--Blue1);
  transition: ease 0.3s all;
  padding: 5px;
}

.hamburgerContents a:hover {
  background-color:  var(--Blue2);
}

.authFormContainer {
  transition: ease 0.3s all;
  display: flex;
  flex-flow: column;
}

.icli {
  color: var(--Red2);
}
.red {
  background-color: var(--Red1) !important;
}
.red:hover {
  background-color: var(--Red2) !important;
}

.authContainer {
  position: fixed;
  right: 0px;
  top: 0px;
  width: auto;
  display: flex;
  flex-flow: row;
  z-index: 1001;
  height: 3vh;
  max-height: 64px;
  min-height: 30px;
}

.authContainer button {
  background-color: var(--Blue1);
  cursor: pointer;
  border: none;
  border-radius: 5px;
  margin: 2px;
  color: white;
  transition: ease 0.3s all;
  height: 26px;
}

.authContainer button:hover {
  background-color: var(--Blue2);
}

.authContainerMToggle {
  position: fixed;
  right: 0;
  top: 0;
  width: 30px;
  height: 30px;
  background-color: var(--Blue1);
  border-radius: 0 0 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: ease 0.3s all;
}

.authContainerMToggle:hover {
  background-color: var(--Blue2);
}

.authContainerM {
  display: flex;
  flex-flow: column;
  position: fixed;
  right: -25vw;
  top: 30px;
  padding: 2px;
  transition: ease 0.3s all;
  background-color: var(--Transparent50p);
  border-radius: 0 0 0 10px;
  box-shadow: 0 4px 8px 0 var(--Transparent25p),0 6px 20px 0 var(--Transparent50p);
}

.authContainerM.active {
  right: 0;
}

.authContainerM button {
  margin: 2px;
}

.registerForm {
  position:fixed;
  left: 100vw;
  margin: 10px;
  width: 270px;
  align-self: center;
  transition: ease 0.3s all;
  color: white;
}

.registerForm.active{
  transform: translateX(-290px);
}

.registerForm h2 {
  align-self: center;
  margin: 10px 0 10px 0;
}

.registerForm h6 {
  margin: 2px;
}

.registerForm form {
  display: flex;
  flex-flow: column;
  align-items: flex-end;
  max-width: 75vw;
  background-color: var(--Gray4);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 5px;
}

.registerForm form div {
  margin: 3px;
}

.registerForm form div label {
  font-size: 1em;
}

.registerForm form div input {
  background-color: var(--Gray6);
  border-radius: 5px;
  font-size: 1em;
  padding: 5px;
  margin: 0 5px 0 5px;
  color: white;
}

.form-check-input {
  cursor: pointer;
}

.registerForm button {
  width: 100%;
  background-color: var(--Blue1);
  color: white;
  border-radius: 5px;
  border: none;
  height: 25px;
  cursor: pointer;
  transition: ease 0.3s all;
}

.registerForm button:hover {
  background-color: var(--Blue2);
}

.registerForm button:disabled {
  background-color: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.5);
  cursor: not-allowed;
}

.registerForm form a {
  background-color: var(--Blue1);
  padding: 5px;
  align-self: center;
  cursor: pointer;
  border: none;
  text-decoration: none;
  color: white;
  border-radius: 5px;
  margin: 2px;
  transition: ease 0.3s all;
}

.registerForm form a:hover {
  background-color: var(--Blue2);
}

.popupFormContainer {
  align-items: center;
  background-color: var(--Transparent50p) !important;
  display: flex !important;
  height: calc(100vh - 30px);
  justify-content: center;
  left: 0;
  position: fixed;
  top: 30px;
  width: 100vw;
  margin: 0 !important;
  padding: 0 !important;
  cursor: default;
}

.popupFormContainer form {
  display: flex;
  background-color: var(--Gray4);
  padding: 5px;
  border-radius: 5px;
  margin: 5px;
  align-items: center;
  max-width: 35vw;
}
.popupFormContainer div button {
  width: 10em;
  margin: 2px;
}

.App-body {
  height: calc(100vh - 30px);
  overflow-y: auto;
  position: relative;
  top: 30px;
  background-color: var(--Gray6);
  box-shadow: 0 4px 8px 0 var(--Transparent25p), 0 6px 20px 0 var(--Transparent50p);
  color: white;
  display: flex;
  flex-flow: column;
  width: 65vw;
  min-width: 360px;
  align-items: center;
  z-index: -1;
}
.App-body.mobile {
  width: 100vw;
}

.App-body h2, .App-body h3 {
  margin-left: 10px;
  margin-right: 10px;
}

.App-list {
  position: relative;
  min-height: 400px;
  min-width: 344px;
  max-width: 770px;
  width: calc(100% - 6px);
  display: flex;
  flex-flow: column;
  overflow-y: auto;
  border: inset;
  border-radius: 10px;
  border-color: var(--Transparent25p);
}

.App-list:hover {
  overflow-y: auto;
}

.App-tile {
  min-width: 299px;
  max-width: 740px;
  min-height: 164px;
  width: calc(100% - 10px);
  border: none;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 var(--Transparent25p), 0 6px 20px 0 var(--Transparent50p);
  background-color: var(--Gray4);
  cursor: pointer;
  margin: 5px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(5, 1fr);
  transition: ease 0.3s all;
  text-decoration: none;
  color: white;
}

.App-tile:hover {
  background-color: var(--Gray5);
}

.App-tile h4 {
  align-self: center;
  max-height: 90px;
  grid-column-start: 2;
  grid-column-end: span 4;
  grid-row-start: 2;
  grid-row-end: span 4;
  margin: 5px;
  overflow-y: auto;
}

.App-tile h3 {
  align-self: center;
  grid-column-start: 2;
  grid-column-end: span 4;
  margin: 0 5px 0 5px;
}

.App-tile img {
  align-self: center;
  justify-self: center;
  grid-row-start: 1;
  grid-row-end: span 5;
  height: 60%;
  margin: 5px;
}

/*WTCM css*/
.wtcmFilterContainer {
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
}

.wtcmFileInput {
  justify-self: center;
}