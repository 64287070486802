.treeItemDir {
  display: flex;
  flex-flow: column;
  align-self: start;
  width: 100%;
}
.treeDirTitle h3:hover {
  background-color: var(--Gray4);
}
.treeItemDir ul {
  margin: 0px;
  padding-left: 41px;
}
.treeItemContainer::marker {
  content: "     |          |          |          |          |          |          |          |-->";
}

.treeDirTitle {
  padding: .25em;
  max-height: 1.5em;
  align-self: start;
  background-color: var(--Gray3);
  margin: 2px !important;
  border-radius: 5px;
  cursor: pointer;
  position:relative;
  width: calc(100% - .5em - 4px);
  display: flex;
  transition: ease 0.3s all;
  text-wrap: nowrap;
  align-items: center;
}
.treeDirTitle h3 {
  margin: 0 !important;
  padding: 2px;
}

.treeItemFile {
  padding: 0.25em;
  background-color: var(--Gray5);
  border-radius: 5px;
  margin: 2px;
  cursor: pointer;
  transition: ease 0.3s all;
  display:flex;
  align-items: center;
}
.treeItemFile:hover {
  background-color: var(--Gray4);
}
.treeItemFileName {
  margin: 0 5px;
}

.treeItemSize {
  text-align: end;
  width: 100%;
}

.treeItemFile button {
  text-wrap: nowrap;
  margin: 0 2px;
  height: 2em;
}

.treeItemDir button {
  text-wrap: nowrap;
  margin: 0 2px;
  height: 2em;
}

.dirDelete {
  background-color: var(--Red1);
}
.dirDelete:hover {
  background-color: var(--Red2);
}

.delConfirmSubmit {
  background-color: var(--Red1);
}
.delConfirmSubmit:hover {
  background-color: var(--Red2);
}
.confirmDeleteInputField input {
  margin: 0 4px;
}

.fileUpload {
  display: flex;
  align-items: center;
  justify-content: center;
}
.dirNameInputField {
  display: flex;
  align-items: center;
  justify-content: center;
}

#dirNameInput {
  margin: 0 5px;
}
#uploadFileMulti {
  margin: 0 5px;
}

.storageSize {
  background-color: var(--Gray3);
  padding: 5px;
  border-radius: 5px;
}
.storageOverSize {
  background-color: var(--Red2) !important;
}