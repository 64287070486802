.gContainer {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  width: 100%;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: center;
  padding-top: 10px;
}

.gCTile {
  height: auto;
  width: 25vw;
  min-width: 340px;
  background-color: var(--Gray3);
  border-radius: 5px;
  padding: 5px;
  margin: 0.5vh 0.5vw;
  font-size: small;
  max-width: 400px;
}

.gCTile h4 {
  margin: 5px;
}

.gControlsContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.gAdminExpander button {
  margin: 5px;
  width: calc(100% - 10px);
}

.gAdminControlsContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 0 5px 0 5px;
}

.gCI {
  margin: 5px;
  padding: 5px;
  transition: ease 0.3s background-color;
  border-radius: 5px;
  background-color: var(--Blue1);
}

.gridWMD {
  grid-column-end: span 2;
}

.gridHMD {
  grid-row-end: span 2;
}

.gridWLG {
  grid-column-end: span 3;
}

.gridHLG {
  grid-row-end: span 3;
}

.gridWXL {
  grid-column-end: span 4;
}

.gridHXL {
  grid-row-end: span 4;
}

.gCI:hover {
  background-color: var(--Blue2);
}

.gCI h6 {
  margin: 5px;
}

.audioTile {
  margin: 5px;
}

.dropdownContainer {
  display: flex;
  margin-bottom: 5px;
}

.dropdown {
  width: 85%;
}

.dropdownFull {
  width: 100%;
}

.dropdown-toggle {
  width: 100%;
}

.dropdown-menu {
  display: none;
  flex-flow: row;
  flex-wrap: wrap;
  justify-content: center;
  background-color: var(--Transparent75p);
  box-shadow: 0 4px 8px 0 var(--Transparent25p), 0 6px 20px 0 var(--Transparent50p);
  left: inherit !important;
  top: inherit !important;
  width: calc(25vw - 20px);
  min-width: 330px;
  transform: translate(0px, 0px) !important;
  border-radius: 5px;
}

.dropdown-menu.show {
  display: flex !important;
}

.dropdown-item {
  border-radius:  5px;
  background-color: var(--Blue1);
  transition: ease 0.3s background-color;
  color: white;
  text-decoration: none;
  margin: 5px;
  padding: 5px;
}

.dropdown-item:hover {
  background-color: var(--Blue2);
  text-decoration: none;
  color: white;
}

.dropdown-item-toggle {
  cursor: inherit;
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 5px;
  height: 50px;
  background-color: var(--Gray2);
  border-radius: 5px;
  margin: 2px;
}

.dropdown-item-toggle .toggleSlider {
  margin: 0 20px;
}

.dropdownContainer .dropdownFull .dropdown-menu {
  width: calc(25vw - 20px);
  min-width: 330px;
  max-width: 390px;
}

.gAdminControlsContainer .dropdownFull .dropdown-menu {
  width: calc(25vw - 30px);
  min-width: 320px;
  max-width: 380px;
  transform: translate(5px, -5px) !important;
  z-index: 1;
}

.leaveVCButton {
  background-color: darkred;
  width: 15%;
  min-width: 3em;
}

.leaveVCButton:hover {
  background-color: red;
}

.songSearch {
  padding: 5px;
  width: calc(100% - 45px);
  border-radius: 5px;
  color: white;
  background-color: var(--Gray2);
  border-color: var(--Blue1);
}

.songInputSubmit {
  padding: 5px;
  width: 25px;
  margin: 2px;
  height: 25px;
}

.nowPlaying {
  margin: 5px;
}

.vidInfoContainer {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 5px;
  grid-template-rows: repeat(3, 2em);
  grid-row-gap: 5px;
  padding: 5px;
  margin: 5px;
  width: calc(100% - 20px);
  align-items: center;
  background-color: var(--Gray4);
  border-radius: 5px;
  transition: ease 0.3s background-color;
}

.vidInfoContainerSM {
  grid-template-columns: repeat(3, 1fr) !important;
  grid-template-rows: repeat(4, auto) !important;
}

.vidInfoContainer:hover {
  background-color: var(--Gray5);
}

.albumArt {
  height: 100%;
  max-width: 100%;
  grid-row-end: span 3;
  border-radius: 5px;
}

.songTitle {
  width: 100%;
}

.songTitle:hover {
  text-decoration: underline;
  cursor: pointer;
}

.songTrackSource {
  grid-row-start: 4;
  width: 100%;
  text-align: start;
}

.songTrackSourceSM {
  grid-row-start: 3 !important;
}

.songAddedBy {
  grid-column-start: 5;
  grid-row-start: 4;
  width: 100%;
  text-align: end;
}

.songAddedBySM {
  grid-row-start: 3 !important;
  grid-column-start: 3 !important;
}

.rmSong {
  background-color: darkred;
  grid-column-start: 2;
  grid-row-start: 3;
}

.rmSong:hover {
  background-color: red;
}

.audioQueueContainer {
  margin: 5px;
}

.audioQueueList {
  display: flex;
  flex-flow: column;
  max-height: 300px;
  overflow-y: auto;
}

.pauseResumeBtn {
  grid-column-start: 4;
}

.skipBtn {
  grid-column-start: 5;
}

.stopBtn {
  grid-column-start: 6;
  background-color: darkred;
}

.stopBtn:hover {
  background-color: red;
}

.toggleSwitch {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  padding: 5px;
}

.toggleSwitch input {
  width: 0;
  height: 0;
}

.toggleSlider {
  position: relative;
  width: 35px;
  height: 20px;
  display: flex;
  cursor: pointer;
  background-color: #ccc;
  transition: ease 0.3s all;
  border-radius: 10px;
  margin-left: 5px;
}

.toggleSlider:before {
  position: relative;
  content: "";
  height: 18px;
  width: 18px;
  top: 1px;
  left: 1px;
  background-color: white;
  transition: ease 0.3s all;
  border-radius: 50%;
}

input:checked + .toggleSlider {
  background-color: var(--Blue1);
}

input:checked + .toggleSlider:hover {
  background-color: var(--Blue2);
}

input:checked + .toggleSlider:before {
  -webkit-transform: translateX(15px);
  -ms-transform: translateX(15px);
  transform: translateX(15px);
}

.consoleToggle {
  margin-top: 5px;
  width: 10vw;
  min-width: 150px;
}

.cmdToggleExpander {
  padding: 5px;
}

.cmdToggleExpander button {
  width: 150px;
  margin: 0 5px;
}

.cmdTogglesContainer {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  height: auto;
  align-items: center;
  justify-content: center;
}

.adminConsoleContainer {
  text-align: left;
  background-color: var(--Gray3);
  width: calc(65vw - 50px);
  min-width: 340px;
  position: relative;
  margin-top: 5px;
  padding: 5px;
  border-radius: 5px;
  display: flex;
  flex-flow: column;
}

.adminConsoleLogs {
  display: flex;
  flex-flow: column;
  overflow-y: auto;
  height: 300px;
  margin-bottom: 5px;
}

.consoleItem {
  background: var(--Gray2);
  border-radius: 5px;
  margin: 2px;
  padding: 4px;
}

.adminConsoleInput {
  padding: 5px;
  border-radius: 5px;
  color: white;
  background-color: var(--Gray3);
  border-color: var(--Blue1);
}

.consoleINFO {
  color: var(--Blue2);
}

.consoleWARN {
  color: yellow;
}

.consoleERR {
  color: red;
}

input.rangeSlider {
  cursor: pointer;
}

button {
  width: auto;
  padding: 5px;
  background-color: var(--Blue1);
  border-style: none;
  border-radius: 5px;
  transition: ease 0.3s background-color;
  color: white;
}

button:hover {
  background-color: var(--Blue2);
  cursor: pointer;
}

.dropdown-item-toggle input {
  width: 0;
  height: 0;
}

.cmdToggleExpander {
  padding: 5px;
}

.cacheSearchResults {
  display: flex;
  flex-flow:column;
  flex-wrap: nowrap;
  overflow-y:auto;
  position: fixed;
  max-width: 380px;
  width: calc(25vw - 30px);
  min-width: 320px;
  padding: 5px;
  margin: 0px;
  height: auto;
  max-height: 150px;
  border-radius: 5px;
  background-color: var(--Transparent50p);
}

.cacheSearchItem {
  height: 30px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(2, 1fr);
  padding: 2px;
  border-style: solid;
  border-width:1px;
  border-radius: 5px;
  margin: 1px 0;
}
.cacheSearchItem:hover {
  cursor: pointer;
  text-decoration: underline;
}

.cacheSearchItemTitle {
  grid-row-start: 1;
  grid-column-start: 2;
  grid-column-end: span 4;
}

.cacheSearchItemDuration {
  grid-column-start: 2;
  grid-column-end: span 2;
  grid-row-start: 2;
}

.cacheSearchItemThumbnail {
  position: relative;
  grid-row-start: 1;
  grid-row-end: span 2;
  grid-column-start: 1;
  grid-column-end: span 2;
  height: 30px;
  align-self: center;
}

.cacheSearchItemLP {
  grid-row-start: 2;
  grid-column-start: 4;
  grid-column-end: span 2;
}