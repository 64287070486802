.profileGridContainer {
  display: grid;
  grid-template-rows: repeat(6, 1fr);
  grid-template-columns: repeat(5, 1fr);
  padding: 5px;
  font-size: 1.5em;
  align-items: center;
  justify-content: center;
}

.unameDisplay {
  display: flex;
  grid-column-start: 1;
  grid-column-end: span 4;
  justify-content: left;
  align-items: center;
  font-size: 2em;
}

.editBtn {
  display: flex;
  background-color: var(--Blue1);
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  height: 2em;
  width: 50%;
  justify-self: end;
}

.profileItem {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--Gray3);
  margin: 3px;
  padding: 5px;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 var(--Transparent25p), 0 6px 20px 0 var(--Transparent50p);
  height: 100%;
}

.profileForm {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  padding: 5px;
}

.profileForm form div {
  margin: 5px;
  padding: 10px;
  font-size: 1.5em;
  display: flex;
  flex-flow: column;
  background-color: var(--Gray3);
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 var(--Transparent25p), 0 6px 20px 0 var(--Transparent50p);
  align-items: center;
}

button:disabled {
  background-color: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.5);
  cursor: not-allowed;
}