.sPanelItem {
  display: flex;
  flex-flow: column;
  flex-wrap: nowrap;
  width: calc(100% - 10px);
  border-radius: 10px;
  background-color: var(--Gray4);
  margin: 5px;
}

.sPanelGridContainer {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(2, 1fr);
  width: 100%;
}

.SadminPanelContainer {
  display: flex;
  flex-flow: column;  
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.HardwarePanel {
  height: 40%;
  width: 80%;
  margin:10px;
  text-align: left;
}

.ServerPanel {
  background-color: var(--Gray5);
  border-color: var(--Gray4);
  border-radius: 10px;
  border-style: inset;
  border-width: 3px;
  height: 80%;
  overflow: auto;
  width: 80%;
} 

.sPanelItemTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-row-start: 1;
  grid-row-end: span 1;
  grid-column-start: 1;
  grid-column-end: span 1;
}

.sPanelItemStatus {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column-start: 2;
  grid-column-end: span 1;
  grid-row-start: 1;
  grid-row-end: span 1;
}

.sPanelItemUptime {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-row-start: 1;
  grid-row-end: span 1;
  grid-column-start: 3;
  grid-column-end: span 1;
}

.sPanelItemResMem {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-row-start: 2;
  grid-row-end: span 1;
  grid-column-start: 4;
  grid-column-end: span 1;
}

.sPanelItemResCpu {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-row-start: 2;
  grid-row-end: span 1;
  grid-column-start: 5;
  grid-column-end: span 1;
}

.startBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-row-start: 2;
  grid-row-end: span 1;
  grid-column-start: 1;
  grid-column-end: span 1;
  margin: 10px;
}

.restartBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-row-start: 2;
  grid-row-end: span 1;
  grid-column-start: 2;
  grid-column-end: span 1;
  margin: 10px;
}

.sPanelItem .stopBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-row-start: 2;
  grid-row-end: span 1;
  grid-column-start: 3;
  grid-column-end: span 1;
  margin: 10px;
}

.ConsoleContainer {
  padding: 5px;
  display: flex;
  flex-flow: column;
}

.ConsoleFilterContainer {
  display: flex;
  flex-flow: row;
  align-items: center;
  padding: 2px;
}

.ConsoleFilterButton {
  width: 50px;
  margin: 0 2px;
}

.ConsoleFilterContainer input {
  margin: 0 2px;
  width: 100%;
}

.showConsole {
  align-items: center;
  display: flex;
  grid-column-start: 5;
  grid-column-end: span 1;
  grid-row-end: span 1;
  grid-row-start: 1;
  justify-content: center;
  margin: 10px;
  color: white;
}

.LogsContainer {
  background-color: var(--Gray6);
  border-color: var(--Gray1);
  border-radius: 10px;
  border-style: inset;
  height: auto;
  max-height: 350px;
  min-height: 36px;
  list-style: none;
  overflow-y: auto;
  padding: 5px;
}

.ConsoleItem {
  border-radius: 5px;
  background-color: var(--Gray5);
  padding: 5px;
  margin:  4px;
  text-align: left;
  white-space: pre-wrap;
}