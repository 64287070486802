.blogContainer {
  width: 100%;
  display: flex;
  height: 100%;
}

.blogNavigatorContainer {
  width: 25%;
  height: 100%;
  overflow: auto;
  background-color: var(--Gray4);
  box-shadow: 0 4px 8px 0 var(--Transparent25p), 0 6px 20px 0 var(--Transparent50p);
}

.blogNavigator {
  display: flex;
  flex-flow: column;
  text-align: start;
}

.blogItemContainer {
  width: 75%;
  height: 100%;
  overflow: auto;
}

.blogNavigator h3 {
  margin: 5px;
}

.blogListContainer {
  margin: 5px;
  padding: 0;
}

.blogChannel {
  border-radius: 5px;
  padding: 2.5px;
  list-style-type: none;
  margin: 10px;
  transition: ease 0.3s all;
  cursor: pointer;
}

.blogChannelTitle {
  margin: 5px;
}

.blogChannelDesc {
  margin: 5px;
}

.blogChannel:hover {
  background-color: var(--Gray6);
}

.blogChannel.bcl:hover {
    background-color: var(--Gray5);
}

.blogChannel.selected {
    background-color: var(--Gray6);
}

.blogChannel.bcl.selected {
    background-color: var(--Gray5);
}

.entry_component {margin: 5px 0;}

.component_list {
    list-style: none;
    padding: 0;
    margin: 5px 0;
}

.entry_component div {
    display: grid;
    grid-template-rows: repeat(2, auto);
    grid-template-columns: repeat(5, auto);
    max-width: 100%;
}

.entry_component div label.form-label {
    grid-row-start: 1;
    grid-column-start: 1;
    grid-column-end: span 4;
    display: flex;
    align-self: center;
    justify-self: left;
    margin-left: 20px;
}

.entry_component div button {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    justify-self: right;
    margin: 0px !important;
    margin-right: 10px !important;
    background-color: var(--Red1);
}

.entry_component div textarea {
    display: flex;
    grid-column-end: span 5;
    margin: 10px;
    background-color: var(--Gray3);
    color: white;
    border-radius: 5px;
    border-color: var(--Blue1);
    border-width: 2px;
    width: calc(100% - 25px) !important;
    max-height: 400px;
    height: 6em;
    min-height: 6em;
}

.entry_component div button:hover {
    background-color: var(--Red2);
}

.blogItemContainer form button {
    margin: 3px;
}
.entry_component div input[type=file] {
  display: flex;
  grid-column-end: span 5;
  align-self: center;
  justify-self: center;
  max-width: 100%;
}